export function SquareLogo({ className }: { className?: string }) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg
      className={className}
      viewBox="0 0 1280 1280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M638.499 39L1159 339.289V939.866L987 1039.1L986.706 1039.27L812.602 1139.71L638.499 1240.16L121 939.866V339.29L292 240.063L292.302 239.888L465.401 139.444L638.499 39ZM293 840.538L465 938.841V729.078H812V939.785L986.84 1039.19L986 440.867L812 340.867V550.078H465V339.868L292 240.063L293 840.538ZM293 1039.67V840.538L121.049 939.894L293 1039.67ZM1159 339.289L986 440.867V239.481L1159 339.289Z"
        className="fill-indigo-900"
      />
      <path d="M986 440.867L1159 339.289L986 239.481V440.867Z" className="fill-slate-100" />
      <path
        d="M293 840.538L465 938.841V729.078H812V939.785L986.84 1039.19L986 440.867L812 340.867V550.078H465V339.868L292 240.063L293 840.538Z"
        className="fill-white"
      />
      <path d="M293 1039.67V840.538L121.049 939.894L293 1039.67Z" className="fill-slate-100" />
    </svg>
  );
}
