import { cn } from "../../utils";
import { SquareLogo } from "./svgIcons/SquareLogo";

interface LogoProps {
  variant?: "square" | "rectangle";
  size?: "medium";
  className?: string;
}

export function Logo({ variant = "square", size = "medium", className }: LogoProps) {
  if (variant === "square") {
    return <SquareLogo className={cn("h-16 w-16", className, size)} />;
  }
  if (variant === "rectangle") {
    return (
      <span className={cn("inline-flex items-center gap-3", className)}>
        <SquareLogo className={cn("h-12 w-12", size)} />
        <span className="text-3xl font-bold text-primary">
          Hub
          <span className="relative bg-gradient-to-r from-primary to-secondaryLight bg-clip-text text-transparent">
            Desk
          </span>
        </span>
      </span>
    );
  }
}
